var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pd-2"},[_c('h1',[_vm._v("Маршрутные листы")]),_c('v-form',{ref:"roadListForm"},[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('formating-data-piccer',{attrs:{"show-label":"Начиная с даты"},model:{value:(_vm.startData),callback:function ($$v) {_vm.startData=$$v},expression:"startData"}})],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('formating-data-piccer',{attrs:{"show-label":"По дату"},model:{value:(_vm.endData),callback:function ($$v) {_vm.endData=$$v},expression:"endData"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.statusItems,"label":"Статус","item-text":"name","item-value":"value","hide-details":"true"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"md":"2","cols":"12"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"width":"100%","color":"success"},on:{"click":function($event){return _vm.getRoadList()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-filter-outline")]),_vm._v(" Найти ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"width":"100%","color":"success"},on:{"click":function($event){return _vm.addRoadList();}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Создать маршрутный лист ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"report_table",attrs:{"locale":"ru-RU","headers":_vm.headers,"items":_vm.roadLists,"items-per-page":30,"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  itemsPerPageText:'Записей на странице',
                  pageText: '{0}-{1} из {2}'
                  }},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
                  var item = ref.item;
return [_c('formating-data-piccer',{attrs:{"show-label":"Дата"},on:{"change":function($event){return _vm.updateList(item)}},model:{value:(item.data),callback:function ($$v) {_vm.$set(item, "data", $$v)},expression:"item.data"}})]}},{key:"item.driver",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{staticClass:"fieldInTable",attrs:{"label":"Водитель"},on:{"change":function($event){return _vm.updateList(item)}},model:{value:(item.driver),callback:function ($$v) {_vm.$set(item, "driver", $$v)},expression:"item.driver"}})]}},{key:"item.comment",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{staticClass:"fieldInTable",attrs:{"label":"Комментарий"},on:{"change":function($event){return _vm.updateList(item)}},model:{value:(item.comment),callback:function ($$v) {_vm.$set(item, "comment", $$v)},expression:"item.comment"}})]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [_c('v-select',{attrs:{"items":['Активный','Выполнен'],"label":"Статус","hide-details":"true,"},on:{"change":function($event){return _vm.updateList(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.control",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Редактировать заказ"},on:{"click":function($event){return _vm.$router.push({name:'editroadlist', params: {listid: item.id}})}}},[_vm._v("mdi-clipboard-edit-outline")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],attrs:{"border":"right","colored-border":"","type":_vm.alertType,"elevation":"2"}},[_vm._v(_vm._s(_vm.message))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }