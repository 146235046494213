var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pd-2"},[_c('h1',[_vm._v("Отчет о продажах")]),_c('v-form',{ref:"filterReportForm"},[_c('v-row',[_c('v-col',[_c('formating-data-piccer',{attrs:{"show-label":"Начиная с даты"},model:{value:(_vm.startData),callback:function ($$v) {_vm.startData=$$v},expression:"startData"}})],1),_c('v-col',[_c('formating-data-piccer',{attrs:{"show-label":"По дату"},model:{value:(_vm.endData),callback:function ($$v) {_vm.endData=$$v},expression:"endData"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.MANAGER_EMAIL_LIST,"label":"Менеджер","item-text":"name","item-value":"mail","hide-details":"true,"},model:{value:(_vm.managerEmail),callback:function ($$v) {_vm.managerEmail=$$v},expression:"managerEmail"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-3",attrs:{"width":"100%","color":"success"},on:{"click":function($event){return _vm.getReport()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-chart-arc")]),_vm._v(" Сформировать отчет ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"report_table",attrs:{"locale":"ru-RU","headers":_vm.headers,"items":_vm.mngReport,"items-per-page":30,"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  itemsPerPageText:'Записей на странице',
                  pageText: '{0}-{1} из {2}'
                  }}})],1)],1),_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Детализация по менеджерам")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mt-4 mb-4",attrs:{"disabled":_vm.mngDetails.length == 0,"target":"_blank","href":'https://lightsnab.ru/wp-content/themes/light-shop/excel_kp/details.php?start='+_vm.startData+'&end='+_vm.endData+'&manager='+_vm.managerEmail,"color":"success"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Сохранить в excel ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("№")]),_c('th',{staticClass:"text-left"},[_vm._v("Номер заказа")]),_c('th',{staticClass:"text-left"},[_vm._v("Менеджер")]),_c('th',{staticClass:"text-left"},[_vm._v("Дата выполнения")]),_c('th',{staticClass:"text-left"},[_vm._v("Клиент")]),_c('th',{staticClass:"text-left"},[_vm._v("Счет 1С")]),_c('th',{staticClass:"text-left"},[_vm._v("Цена безнал")]),_c('th',{staticClass:"text-left"},[_vm._v("Цена нал")])])]),_c('tbody',_vm._l((_vm.mngDetails),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index))]),_c('td',[_vm._v(_vm._s(item.zak_numbet))]),_c('td',[_vm._v(_vm._s(item.mng_name))]),_c('td',[_vm._v(_vm._s(item.zak_final_data))]),_c('td',[_vm._v(_vm._s(item.klient_name))]),_c('td',[_vm._v(_vm._s(item.nomer_sheta_1c))]),_c('td',[_vm._v(_vm._s(item.summa_sheta_1c))]),_c('td',[_vm._v(_vm._s(item.total_summ))])])}),0)]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],attrs:{"border":"right","colored-border":"","type":_vm.alertType,"elevation":"2"}},[_vm._v(_vm._s(_vm.message))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }