var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pd-2"},[_c('h1',[_vm._v("Добавить склад")]),_c('v-form',{ref:"addingSkaladForm"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.requiredRules,"label":"Наименование склада","prepend-icon":"mdi-form-textbox"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"rules":_vm.requiredRules,"label":"Адрес склада","prepend-icon":"mdi-home-city-outline"},model:{value:(_vm.adres),callback:function ($$v) {_vm.adres=$$v},expression:"adres"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+# (###) ###-##-##'),expression:"'+# (###) ###-##-##'"}],attrs:{"rules":_vm.phoneRules,"label":"Телефон","prepend-inner-icon":"mdi-card-account-phone"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Геопозиция","prepend-icon":"mdi-map-marker-outline"},model:{value:(_vm.geo),callback:function ($$v) {_vm.geo=$$v},expression:"geo"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],attrs:{"border":"right","colored-border":"","type":_vm.alertType,"elevation":"2"}},[_vm._v(_vm._s(_vm.message))])],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.addSkladToBase()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus")]),_vm._v(" Добавить склад в справочник ")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"sclad_table",attrs:{"locale":"ru-RU","headers":_vm.headers,"items":_vm.allSclads,"items-per-page":30,"footer-props":{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  itemsPerPageText:'Записей на странице',
                  pageText: '{0}-{1} из {2}'
                  }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{staticClass:"fieldInTable",attrs:{"rules":_vm.requiredRules},on:{"change":function($event){return _vm.updateSklad(item)}},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.adres",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{staticClass:"fieldInTable",attrs:{"rules":_vm.requiredRules},on:{"change":function($event){return _vm.updateSklad(item)}},model:{value:(item.adres),callback:function ($$v) {_vm.$set(item, "adres", $$v)},expression:"item.adres"}})]}},{key:"item.phone",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+# (###) ###-##-##'),expression:"'+# (###) ###-##-##'"}],attrs:{"rules":_vm.phoneRules},on:{"change":function($event){return _vm.updateSklad(item)}},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})]}},{key:"item.geo",fn:function(ref){
                  var item = ref.item;
return [_c('v-text-field',{staticClass:"fieldInTable",attrs:{"rules":_vm.requiredRules},on:{"change":function($event){return _vm.updateSklad(item)}},model:{value:(item.geo),callback:function ($$v) {_vm.$set(item, "geo", $$v)},expression:"item.geo"}})]}},{key:"item.control",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"title":"Удалить склад"},on:{"click":function($event){return _vm.deleteSklad(item)}}},[_vm._v("mdi-trash-can-outline")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }